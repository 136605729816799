import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router';

import {
  PAGE_GROWTH,
  PAGE_PRICE_SEGMENTS,
  PAGE_PRODUCTS,
  PAGE_SALES,
  PAGE_TRENDS,
} from 'constants';
import { F } from 'constants/filter';
import { useModalFilter } from 'context/filter.modal.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUserTariffs } from 'context/user.tariffs.context';
import { FilterModal } from './components';
import { Brands, Categories, NewAdvertisingFilter, Sellers } from './outer';
import { PromoCategories } from './outer/PromoCategories';

export const FilterModalOuter = ({ opened, onCancel }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    state: { localChips, localFilter, touched },
    cleanCategories,
    cleanBrands,
    cleanSellers,
    cleanSubjects,
    cleanPromoCategories,
    resetLocalFilter,
    setIsNew,
    setHasAdvertising,
  } = useModalFilter();

  const {
    filter,
    chips,
    updateChips,
    setIsNew: setIsNewFilter,
    setHasAdvertising: setHasAdvertisingFilter,
  } = useFilterReducer();

  const { isSellersAvailable } = useUserTariffs();

  const onSuccessApplyFilter = () => {
    const pageWithoutRedirect = [PAGE_PRICE_SEGMENTS, PAGE_SALES, PAGE_TRENDS, PAGE_GROWTH];

    if (!pageWithoutRedirect.some((i) => location.pathname.includes(i))) {
      navigate(PAGE_PRODUCTS);
    }
  };

  const handleFilterApply = () => {
    setIsNewFilter(!!localFilter.isNew);
    setHasAdvertisingFilter(!!localFilter.hasAdvertising);
    updateChips(localChips);
    resetLocalFilter();

    onSuccessApplyFilter();
  };

  const handleClickCancel = () => {
    resetLocalFilter();
    setIsNew(!!filter.isNew);
    setHasAdvertising(!!filter.hasAdvertising);
    if (onCancel) {
      onCancel();
    }
  };

  const onClean = (type) => {
    if (type === F.CATEGORY) return cleanCategories();
    if (type === F.PROMO_CATEGORY) return cleanPromoCategories(true);
    if (type === F.BRAND) return cleanBrands();
    if (type === F.SELLER) return cleanSellers();
    if (type === F.SUBJECT) return cleanSubjects();
  };

  const categoryCount = touched.category ? localFilter?.category?.length : chips?.category?.length;
  const promoCategoryCount = touched.promo ? localFilter?.promo?.length : chips?.promo?.length;
  const brandsCount = touched.brand ? localFilter?.brand?.length : chips?.brand?.length;
  const sellersCount = touched.seller ? localFilter?.seller?.length : chips?.seller?.length;
  // const subjectsCount = touched.subject ? localFilter?.subject?.length : chips?.subject?.length;

  const TABS = [
    {
      id: 'category',
      label: 'Категория',
      count: categoryCount,
      available: true,
      panel: <Categories />,
    },

    // {
    //   id: 'subject',
    //   label: 'Предмет',
    //   count: subjectsCount,
    //   available: true,
    //   panel: <Subjects />,
    // },
    {
      id: 'brand',
      label: 'Бренд',
      count: brandsCount,
      available: true,
      panel: <Brands />,
    },

    {
      id: 'seller',
      label: 'Продавец',
      count: sellersCount,
      available: isSellersAvailable,
      panel: <Sellers />,
    },
    {
      id: 'promo',
      label: 'Акции',
      count: promoCategoryCount,
      available: true,
      panel: <PromoCategories />,
    },
  ];

  return (
    <FilterModal
      tabs={TABS}
      onTabCleanByType={onClean}
      onSubmit={handleFilterApply}
      onCancel={handleClickCancel}
      onReset={resetLocalFilter}
      additionalContentOnTabLeft={<NewAdvertisingFilter />}
      opened={opened}
    />
  );
};

FilterModalOuter.propTypes = {
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  opened: PropTypes.bool,
};
export default FilterModalOuter;
