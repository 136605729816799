import { Navigate, Outlet, Route, Routes } from 'react-router';

import Header from './Header';
import {
  ActivationLayout,
  DefaultLayout,
  ProductLayout,
  ProfileLayout,
  TableGrowthLayout,
  TableInnerLayout,
  TableMainLayout,
} from './Layout';

import {
  ActivateDemo,
  ActivateDemonstration,
  ActivateFullAccess,
  AdvertisingRates,
  ApiKeys,
  Brands,
  Categories,
  Comparison,
  Dynamic,
  ErrorAccess,
  FAQ,
  GrowthBrands,
  GrowthProducts,
  GrowthSellers,
  GrowthSubjects,
  InnerDelivery,
  InnerInDays,
  InnerInDays2,
  InnerPrices,
  InnerProduct,
  InnerProducts,
  InnerProductsSettings,
  InnerSales,
  InnerWarehouses,
  InnerWeeks,
  Navigation,
  Positions,
  PriceSegments,
  Pricing,
  Products,
  Profile,
  Referrals,
  SalesByDay,
  Sellers,
  SEO,
  SEOCompare,
  SEOHints,
  SEOProduct,
  SeoSmart,
  SEOSmartDescription,
  Subjects,
  Subscriptions,
  SuccessPayment,
  SuccessPaymentUL,
  Tariffs,
  Warehouses,
} from 'pages';

import Notification from 'components/Notification';
import {
  PAGE_ADVERTISING_RATES,
  PAGE_API_KEYS,
  PAGE_BRANDS,
  PAGE_COMPARISON,
  PAGE_GROWTH,
  PAGE_GROWTH_BRANDS,
  PAGE_GROWTH_PRODUCTS,
  PAGE_GROWTH_SELLERS,
  PAGE_GROWTH_SUBJECTS,
  PAGE_INNER,
  PAGE_INNER_DELIVERY_TAB,
  PAGE_INNER_IN_DAYS,
  PAGE_INNER_IN_DAYS_2,
  PAGE_INNER_PRICES_TAB,
  PAGE_INNER_PRODUCT_ID,
  PAGE_INNER_PRODUCTS,
  PAGE_INNER_PRODUCTS_SETTINGS,
  PAGE_INNER_SALES,
  PAGE_INNER_WAREHOUSES,
  PAGE_INNER_WEEKS,
  PAGE_MAIN,
  PAGE_NAVIGATION,
  PAGE_POSITIONS,
  PAGE_PRICE_SEGMENTS,
  PAGE_PRODUCT_ID,
  PAGE_PRODUCTS,
  PAGE_PROFILE,
  PAGE_PROFILE_REFERRALS,
  PAGE_PROFILE_SUBSCRIPTIONS,
  PAGE_PROFILE_TARIFFS,
  PAGE_SALES,
  PAGE_SELLERS,
  PAGE_SEO,
  PAGE_SIGNUP,
  PAGE_SUBJECTS,
  PAGE_SUCCESS_PAYMENT,
  PAGE_SUCCESS_PAYMENT_UL,
  PAGE_TARIFFS,
  PAGE_TRENDS,
  PAGE_WAREHOUSES,
} from 'constants/pages';

import Footer from 'components/Footer';
import InterestingTextModal from 'components/InterestingTextModal';
import DynamicLayout from 'components/Layout/DynamicLayout';
import { PAGE_SEO_COMPARE } from 'constants';
import {
  BY_PRODUCTS,
  PAGE_SEO_BY_PHRASE,
  PAGE_SEO_CLUSTERS,
  PAGE_SEO_HINTS,
  PAGE_SEO_MONITORING,
  PAGE_SEO_PRODUCT,
  PAGE_SEO_SEARCH,
  PAGE_SEO_SMART,
  PAGE_SEO_SMART_DESCRIPTION,
} from 'constants/seo';
import { useLocationPath } from 'hooks/useLocationPath';
import { useAuth } from '../context/auth.context';
import { SEOByPhrase, SEOClusters, SEOMonitoring, SEOSearch } from '../pages/SEO';
import { Error404 } from './Errors';
import MobileInterface from './MobileInterface';
import TopBanner from './TopBanner';
import VideoLessonsModal from './VideoLessonsModal';

const TableRoutes = () => (
  <TableMainLayout>
    <Outlet />
  </TableMainLayout>
);

const TableGrowthRoutes = () => (
  <TableGrowthLayout>
    <Outlet />
  </TableGrowthLayout>
);

export const ActivationRoutes = () => (
  <ActivationLayout>
    <Outlet />
  </ActivationLayout>
);

const ProfileRoutes = () => {
  return (
    <ProfileLayout>
      <Outlet />
    </ProfileLayout>
  );
};

const InnerRoutes = () => {
  return (
    <TableInnerLayout>
      <Outlet />
    </TableInnerLayout>
  );
};

const NotFound = () => <Error404 />;

const ProtectedLayout = () => {
  // this is a must to make default filters!!!
  useLocationPath();
  const { isBasic } = useAuth();
  return (
    <>
      <TopBanner />
      <Header />
      <Notification />
      <Routes>
        <Route path={PAGE_MAIN} element={<TableRoutes />}>
          <Route path={PAGE_BRANDS} element={<Brands />} />
          <Route path={PAGE_PRODUCTS} element={<Products />} />
          <Route path={PAGE_SALES} element={<SalesByDay />} />
          <Route path={PAGE_SUBJECTS} element={<Subjects />} />
          <Route path={PAGE_SELLERS} element={<Sellers />} />
          <Route path={PAGE_WAREHOUSES} element={<Warehouses />} />
          <Route path={PAGE_PRICE_SEGMENTS} element={<PriceSegments />} />

          <Route
            path={PAGE_TRENDS}
            element={
              <DynamicLayout>
                <Dynamic />
              </DynamicLayout>
            }
          />

          <Route index element={<Categories />} />
        </Route>

        <Route path={PAGE_COMPARISON} element={<Comparison />} />

        <Route path={PAGE_GROWTH} element={<TableGrowthRoutes />}>
          <Route index element={<GrowthSubjects />} />
          <Route path={PAGE_GROWTH_SELLERS} element={<GrowthSellers />} />
          <Route path={PAGE_GROWTH_BRANDS} element={<GrowthBrands />} />
          <Route path={PAGE_GROWTH_PRODUCTS} element={<GrowthProducts />} />
          <Route path={PAGE_GROWTH_SUBJECTS} element={<GrowthSubjects />} />
        </Route>

        <Route path={PAGE_API_KEYS} element={<ApiKeys />} />
        <Route path={PAGE_NAVIGATION} element={<Navigation />} />
        <Route path={`${PAGE_PRODUCT_ID}/:tab?`} element={<ProductLayout />} />
        <Route
          path="/faq"
          element={
            <DefaultLayout>
              <FAQ />
            </DefaultLayout>
          }
        />

        <Route path={PAGE_ADVERTISING_RATES} element={<AdvertisingRates />} />
        <Route path={PAGE_PROFILE} element={<ProfileRoutes />}>
          <Route
            path={PAGE_PROFILE_REFERRALS}
            element={<Referrals />}
            title="Реферальная программа"
          />
          <Route path={PAGE_PROFILE_TARIFFS} element={<Tariffs />} title="Тарифы" />
          <Route path={PAGE_PROFILE_SUBSCRIPTIONS} element={<Subscriptions />} />
          <Route index element={<Profile />} />
        </Route>

        <Route path={PAGE_POSITIONS} element={<Positions />} />

        <Route
          path={PAGE_TARIFFS}
          element={
            <DefaultLayout>
              <Tariffs />
            </DefaultLayout>
          }
        />
        <Route
          path={PAGE_SUCCESS_PAYMENT}
          element={
            <DefaultLayout>
              <SuccessPayment />
            </DefaultLayout>
          }
        />
        <Route
          path={PAGE_SUCCESS_PAYMENT_UL}
          element={
            <DefaultLayout>
              <SuccessPaymentUL />
            </DefaultLayout>
          }
        />

        <Route path="/sign-in" element={<Navigate to={PAGE_MAIN} />} />
        <Route path="/recovery-password" element={<Navigate to={PAGE_MAIN} />} />
        <Route path="/reset-password" element={<Navigate to={PAGE_MAIN} />} />
        <Route path={PAGE_SIGNUP} element={<Navigate to={PAGE_MAIN} />} />
        <Route path="/" element={<Navigate to={PAGE_MAIN} />} />

        <Route path="/activate-demo/:tariffId/:token" element={<ActivateDemo />} />

        {!isBasic && <Route path={PAGE_INNER_PRICES_TAB} element={<InnerPrices />} />}
        {!isBasic && (
          <Route path={PAGE_INNER_PRODUCTS_SETTINGS} element={<InnerProductsSettings />} />
        )}
        {!isBasic && <Route path={PAGE_INNER_DELIVERY_TAB} element={<InnerDelivery />} />}
        {!isBasic && (
          <Route path={PAGE_INNER} element={<InnerRoutes />}>
            <Route path={PAGE_INNER_PRODUCTS} element={<InnerProducts />} />
            <Route path={PAGE_INNER_IN_DAYS} element={<InnerInDays />} />
            <Route path={PAGE_INNER_SALES} element={<InnerSales />} />
            <Route path={PAGE_INNER_WEEKS} element={<InnerWeeks />} />
            <Route path={`${PAGE_INNER_PRODUCT_ID}/:tab?`} element={<InnerProduct />} />
            <Route path={PAGE_INNER_IN_DAYS_2} element={<InnerInDays2 />} />
            <Route path={PAGE_INNER_WAREHOUSES} element={<InnerWarehouses />} />
          </Route>
        )}
        {!isBasic && <Route path={PAGE_SEO_COMPARE} element={<SEOCompare />} />}
        {!isBasic && <Route path={PAGE_SEO_MONITORING} element={<SEOMonitoring />} />}
        {!isBasic && <Route path={PAGE_SEO_BY_PHRASE} element={<SEOByPhrase />} />}
        {!isBasic && <Route path={PAGE_SEO_CLUSTERS + '/:tab?'} element={<SEOClusters />} />}
        {!isBasic && <Route path={PAGE_SEO_HINTS} element={<SEOHints />} />}
        {!isBasic && <Route path={PAGE_SEO_SEARCH} element={<SEOSearch />} />}
        {!isBasic && <Route path={PAGE_SEO_SMART} element={<SeoSmart />} />}
        {!isBasic && <Route path={PAGE_SEO_PRODUCT} element={<SEOProduct />} />}
        {!isBasic && <Route path={PAGE_SEO_SMART_DESCRIPTION} element={<SEOSmartDescription />} />}
        {!isBasic && <Route path={`${PAGE_SEO}/:by?/:tab?`} element={<SEO />} />}
        {!isBasic && (
          <Route path={PAGE_SEO} element={<Navigate to={`${PAGE_SEO}/${BY_PRODUCTS}`} />} />
        )}
        {!isBasic && (
          <Route
            path="/pricing"
            element={
              <DefaultLayout>
                <Pricing />
              </DefaultLayout>
            }
          />
        )}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <MobileInterface />
      <InterestingTextModal />
      <VideoLessonsModal />
    </>
  );
};

const AuthenticatedApp = () => {
  return (
    <div className="flex flex-col items-stretch min-h-screen">
      <Routes>
        <Route path="/activate-account" element={<ActivationRoutes />}>
          <Route path="/activate-account/full-access" element={<ActivateFullAccess />} />
          <Route path="/activate-account/demonstration" element={<ActivateDemonstration />} />
        </Route>
        <Route path="/error-access" element={<ErrorAccess />} />
        <Route path="*" element={<ProtectedLayout />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default AuthenticatedApp;
