import { useRef } from 'react';

import QuestionIcon from 'assets/icons/QuestionIcon';
import Checkbox from 'components/Checkbox';
import Popover from 'components/Popover';
import { useUserProducts } from 'context/user.products.context';

const TableMyProductsFilter = () => {
  const { showMyProducts, toggleMyProducts } = useUserProducts();
  const tooltipRef = useRef();
  const tooltipHelpRef = useRef();

  return (
    <div className="flex">
      <div ref={tooltipRef}>
        <Checkbox
          label="Мои Товары"
          onChange={toggleMyProducts}
          name="is-new"
          checked={!!showMyProducts}
          className={'whitespace-nowrap'}
        />
      </div>
      <div ref={tooltipHelpRef} className="ml-1.5 text-gray-300">
        <QuestionIcon width={15} height={15} />
      </div>
      <Popover tooltipRef={tooltipHelpRef} tooltip="Подсвечивать мои товары в выборках" />
    </div>
  );
};

export default TableMyProductsFilter;
